import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    priceFeedLabel: {
      color: '#AEAEAE',
      lineHeigh: '15px',
    },
    divider: {
      margin: '2px 8px',
      height: '16px',
      backgroundColor: '#323336',
    },
  }),
);
