import { TestLocators } from 'constants/Locators';

import { useIsDarkMode } from 'hooks/useIsDarkMode';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsJupyterEnabled } from 'app/containers/PrivateRoute/selectors';

import { ProfileMenu } from './ProfileMenu';
import { ReactComponent as ProfileDarkIcon } from './ProfileMenu/profile-dark.svg';
import { ReactComponent as ProfileLightIcon } from './ProfileMenu/profile-light.svg';
import { useStyles } from './styles';

export const Profile = () => {
  const isJupyterEnabled = useSelector(selectIsJupyterEnabled);
  const classes = useStyles({ isJupyterEnabled });
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isDarkMode = useIsDarkMode();
  const ProfileIcon = isDarkMode ? ProfileDarkIcon : ProfileLightIcon;
  const handleClickProfileMenu = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setProfileMenuAnchorEl(currentTarget);
  };

  const handleCloseProfileMenu = useCallback(() => {
    setProfileMenuAnchorEl(null);
  }, []);

  return (
    <>
      <div className={classes.profile} onClick={handleClickProfileMenu} data-testid={TestLocators.UserProfile}>
        <div className={classes.profileBackground}>
          <div>
            <ProfileIcon className={classes.profileIcon} />
          </div>
        </div>
      </div>
      <ProfileMenu anchorEl={profileMenuAnchorEl} onClose={handleCloseProfileMenu} />
    </>
  );
};
