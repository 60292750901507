import { TestLocators } from 'constants/Locators';

import { Divider } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line no-restricted-imports
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { socket } from 'app/containers/SocketHandler';
import { selectPriceFeed } from 'app/containers/SocketHandler/selectors';
import { actions } from 'app/containers/SocketHandler/slice';
import { PriceFeedData } from 'app/containers/SocketHandler/types';
import { formatByLocale } from 'helpers/formatters';
import { translations } from 'locales/i18n';

import { HeaderItem } from '../HeaderItem';
import { useStyles } from './styles';

export interface PriceFeedProps {
  width: Breakpoint;
}

const numberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const PriceFeedComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const priceFeed = useSelector(selectPriceFeed);

  const handleUpdatePriceFeed = useCallback(
    (data: PriceFeedData) => {
      dispatch(actions.setPriceFeed(data));
    },
    [dispatch],
  );

  useEffect(() => {
    socket?.emit('live_price:init', null, handleUpdatePriceFeed);
    socket?.on('live_price:fetch', handleUpdatePriceFeed);

    return () => {
      socket?.off('live_price:fetch', handleUpdatePriceFeed);
    };
  }, [handleUpdatePriceFeed]);

  return (
    <>
      <HeaderItem
        labelClassName={classes.priceFeedLabel}
        readOnly
        isPriceFeed
        tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
        label={t(translations.PRICE.WTI, {
          value: formatByLocale(priceFeed.WTI, numberFormatOptions),
        })}
        testId={TestLocators.Header_WTI}
      />
      <Divider orientation="vertical" className={classes.divider} />
      <HeaderItem
        labelClassName={classes.priceFeedLabel}
        readOnly
        isPriceFeed
        tooltipText={t(translations.PRICE.TOOLTIP_TEXT)}
        label={t(translations.PRICE.HENRY_HUB, {
          value: formatByLocale(priceFeed.HenryHub, numberFormatOptions),
        })}
        testId={TestLocators.Header_HH}
      />
    </>
  );
};

export const PriceFeed = withWidth()(PriceFeedComponent);
