import { TestLocators } from 'constants/Locators';

import { Drawer } from '@material-ui/core';
import NotificationsActiveSharpIcon from '@material-ui/icons/NotificationsActiveSharp';
import NotificationsSharpIcon from '@material-ui/icons/NotificationsSharp';
import cn from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserInfo } from 'app/containers/PrivateRoute/selectors';
import { notificationsSectionClick, headerNavClick as trackEvent } from 'common/analytics';
import { ProductUpdateType, ProductUpdateTypeGA } from 'types/ProductUpdate';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { NotificationBody } from './NotificationBody';
import { NotificationsWindow } from './NotificationsWindow';
import { notificationsSaga } from './saga';
import { selectData, selectStatus } from './selectors';
import {
  actions as notificationsActions,
  sliceKey as notificationsKey,
  reducer as notificationsReducer,
} from './slice';
import { useStyles } from './styles';

const Notifications = () => {
  useInjectReducer({ key: notificationsKey, reducer: notificationsReducer });
  useInjectSaga({ key: notificationsKey, saga: notificationsSaga });

  const classes = useStyles();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const notifications = useSelector(selectData);
  const userInfo = useSelector(selectUserInfo);

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isNotificationsWindowOpen, setIsNotificationsWindowOpen] = useState(false);
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);
  const [notificationsLightCheck, setNotificationsLightCheck] = useState(false);

  const toggleNotifications = useCallback((isOpen: boolean) => {
    setIsNotificationsOpen(isOpen);
    setNotificationsLightCheck(true);
  }, []);

  useEffect(() => {
    dispatch(notificationsActions.loadNotifications({ productUpdateType: ProductUpdateType.Notification }));
  }, [dispatch]);

  useEffect(() => {
    setShowMarkAllAsRead(notifications.some(({ isRead }) => !isRead));
  }, [notifications, setShowMarkAllAsRead]);

  const hasNewNotifications = useMemo(() => {
    const impactLastVisitedDate = userInfo?.impactLastVisited ? new Date(userInfo?.impactLastVisited).getTime() : 0;
    const latestNotificationDate = notifications[0]?.releaseDate
      ? new Date(notifications[0]?.releaseDate).getTime()
      : 0;
    const notificationsUpToDate = !!notifications.length && impactLastVisitedDate < latestNotificationDate;

    return notificationsUpToDate && !notificationsLightCheck;
  }, [notifications, notificationsLightCheck, userInfo?.impactLastVisited]);

  const handleAllAsRead = () => {
    dispatch(notificationsActions.trackAllNotificationsAsViewed());
    setShowMarkAllAsRead(false);
  };

  const handleSelectNotificationsCategory = useCallback(
    (category: ProductUpdateType) => {
      setIsNotificationsWindowOpen(true);
      toggleNotifications(false);
      dispatch(notificationsActions.setActiveProductUpdateInWindow(category));
      notificationsSectionClick(ProductUpdateTypeGA[category]);
    },
    [setIsNotificationsWindowOpen, toggleNotifications, dispatch],
  );

  return (
    <>
      <div
        onClick={() => {
          trackEvent('Bell Icon');
          toggleNotifications(true);
        }}
        data-testid={TestLocators.Landing_OpenNotificationsPanel}
      >
        <div className={classes.notificationIcon}>
          {hasNewNotifications && <div className={cn(classes.dotIndicator, classes.notificationIconDot)}></div>}
          {isNotificationsOpen ? (
            <NotificationsActiveSharpIcon style={{ fontSize: '18px' }} />
          ) : (
            <NotificationsSharpIcon style={{ fontSize: '18px' }} />
          )}
        </div>
      </div>
      <NotificationsWindow
        notifications={notifications}
        isOpen={isNotificationsWindowOpen}
        hasNewNotifications={hasNewNotifications}
        handleAllAsRead={handleAllAsRead}
        onClose={() => setIsNotificationsWindowOpen(false)}
        handleSelectNotificationsCategory={handleSelectNotificationsCategory}
      />
      <Drawer
        anchor="right"
        open={isNotificationsOpen}
        onClose={() => toggleNotifications(false)}
        BackdropProps={{
          classes: {
            root: classes.notificationsBackdrop,
          },
        }}
        classes={{
          paper: classes.notificationsPaper,
        }}
      >
        <NotificationBody
          status={status}
          notifications={notifications}
          showMarkAllAsRead={showMarkAllAsRead}
          handleAllAsRead={handleAllAsRead}
          handleSelectNotificationsCategory={handleSelectNotificationsCategory}
        />
      </Drawer>
    </>
  );
};

export default Notifications;
