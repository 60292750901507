import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    wrapper: {
      height: 64,
      paddingLeft: '12px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        marginRight: 5,
      },
    },
    productName: {
      lineHeight: '26px',
      font: 'normal 24px "Akkurat", Arial, sans-serif',
      fontWeight: 700,
      color: isDarkTheme ? colors.smokeScreen : colors.textPrimaryDefault,
      display: 'flex',
      alignItems: 'center',
    },
  });
});
