import { api } from 'constants/API';

import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getErrorMessage } from 'utils/error';

import { LoadShapefilePayload, actions } from './slice';

const request = ({ region, filename }: LoadShapefilePayload) =>
  axios.get<{ downloadLink: string }>(`${api.getShapeFilesDownloadLink}/${region}/${filename}`);

function* getShapeFile(action: PayloadAction<LoadShapefilePayload>) {
  try {
    const result: AxiosResponse<{ downloadLink: string }> = yield call(request, action.payload);

    yield put(actions.shapefileLoaded(result.data.downloadLink));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(actions.shapefileError(errorMessage));
  }
}

export function* landingPageShapefileSaga() {
  yield takeLatest(actions.loadShapefile.type, getShapeFile);
}
