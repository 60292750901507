import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ShapefileRegion } from '../constants';
import { LandingPageShapeFileState } from './types';

export const initialState: LandingPageShapeFileState = {
  status: RequestStatus.IDLE,
  error: null,
  filename: '',
  region: null,
  url: null,
};

export interface LoadShapefilePayload {
  region: ShapefileRegion | null;
  filename: string;
}

const shapefileSlice = createSlice({
  name: 'landingPageShapefile',
  initialState,
  reducers: {
    loadShapefile(state, action: PayloadAction<LoadShapefilePayload>) {
      const { region, filename } = action.payload;
      state.status = RequestStatus.LOADING;
      state.error = null;
      state.filename = filename;
      state.region = region;
      state.url = null;
    },
    shapefileLoaded(state, action: PayloadAction<string>) {
      state.status = RequestStatus.SUCCESS;
      state.url = action.payload;
    },
    shapefileError(state, action: PayloadAction<unknown>) {
      state.error = action.payload;
      state.status = RequestStatus.FAILURE;
    },
    resetFilename(state) {
      state.filename = '';
      state.url = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = shapefileSlice;
