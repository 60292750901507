import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    header: {
      paddingRight: theme.spacing(2),
      backgroundColor: isDarkTheme ? colors.baseGrey57 : colors.primaryWhite,
      boxShadow: isDarkTheme ? '0 0 0 1px #444, 0 0 0 2px #121212' : 'none',
    },
    nav: {
      height: '24px',
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      marginRight: '10px',
    },
    upperNav: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexShrink: 0,
      marginRight: '10px',
    },
    rightUpperNavModule: {
      display: 'flex',
      alignItems: 'center',
    },
    headerItem: {
      marginLeft: 8,
    },
  });
});
