import { TestLocators } from 'constants/Locators';

import { Container } from '@material-ui/core';
import { ReactElement, useState } from 'react';

import { Header } from '../Header';
import { HeaderLandingPage } from '../HeaderLandingPage';
import { useStyles } from './styles';

export interface MainLayoutProps {
  children: ReactElement;
  testId?: TestLocators;
}

export function MainLayout({ children, testId }: MainLayoutProps) {
  const classes = useStyles();
  const [header, setHeader] = useState(true);

  return (
    <>
      {header ? <HeaderLandingPage /> : <Header />}
      <main className={classes.mainContainer}>
        <Container maxWidth={false} data-testid={testId} disableGutters>
          <div style={{ marginTop: '5px' }}>
            <button
              onClick={() => setHeader(!header)}
              // eslint-disable-next-line react/jsx-no-literals
            >
              Toggle Header Version
            </button>
          </div>
          {children}
        </Container>
      </main>
    </>
  );
}
