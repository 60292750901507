import { AppRoute } from 'constants/AppRoute';

import withWidth from '@material-ui/core/withWidth';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  selectIsJupyterEnabled,
  selectIsPlanPredictEnabled,
  selectIsSqlEditorEnabled,
  userSelectedDatabase,
} from 'app/containers/PrivateRoute/selectors';
import { headerNavClick as trackEvent } from 'common/analytics';
import { translations } from 'locales/i18n';
import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

import { DownloadShapefile } from './DownloadShapefile';
import { HeaderItem } from './HeaderItem';
import { Help } from './Help';
import { Logo } from './Logo';
import Notifications from './Notifications';
import { PriceFeed } from './PriceFeed';
import { Profile } from './Profile';
import { useStyles } from './styles';
import { NON_ROUTE_HEADER_IDS, getHeaderItemsList, nonRouteHeaderIds } from './utils';

function getActiveMenuItem(pathname: string) {
  if (pathname.includes(AppRoute.PRODUCTION_PREDICTION)) {
    return AppRoute.PRODUCTION_PREDICTION;
  } else if (pathname.includes(AppRoute.WELL_PLANNER)) {
    return AppRoute.WELL_PLANNER;
  } else if (pathname.includes(AppRoute.ECONOMICS)) {
    return AppRoute.ECONOMICS;
  }

  return AppRoute.ROOT;
}

function HeaderComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorHelpEl, setAnchorHelpEl] = useState<HTMLDivElement | null>(null);
  const [shapefileAnchorEl, setShapefileAnchorEl] = useState<HTMLDivElement | null>(null);
  const isSqlEditorEnabled = useSelector(selectIsSqlEditorEnabled);
  const isJupyterEnabled = useSelector(selectIsJupyterEnabled);
  const classes = useStyles({ isJupyterEnabled });
  const isPlanPredictEnabled = useSelector(selectIsPlanPredictEnabled);
  const activeMenuItem = useMemo(() => getActiveMenuItem(pathname), [pathname]);
  const selectedDatabaseFromRedux = useSelector(userSelectedDatabase);

  const headerItemsList = getHeaderItemsList(selectedDatabaseFromRedux, isPlanPredictEnabled, isSqlEditorEnabled);

  const handleMenuItemClick = useCallback(
    ({ currentTarget: { id } }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ([AppRoute.WELL_PLANNER, AppRoute.PRODUCTION_PREDICTION, AppRoute.ECONOMICS].includes(id)) {
        let source = LogSource.UNKNOWN;
        switch (id) {
          case AppRoute.PRODUCTION_PREDICTION:
            source = LogSource.PREDICT_PAGE_TAB;
            break;

          case AppRoute.WELL_PLANNER:
            source = LogSource.PLAN_PAGE_TAB;
            break;

          case AppRoute.ECONOMICS:
            source = LogSource.ECONOMICS_PAGE_TAB;
            break;

          default:
            break;
        }
        log.info({
          event: LogEvent.CLICK,
          source,
        });
      }

      navigate(id);
      trackEvent(id);
    },
    [navigate],
  );

  const handleHelpClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorHelpEl(currentTarget);
      trackEvent('', t(translations.HELP.TITLE));
    },
    [t],
  );

  const handleHelpClose = useCallback(() => {
    setAnchorHelpEl(null);
  }, []);

  const handleShapefileClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setShapefileAnchorEl(currentTarget);
      trackEvent('', t(translations.DOWNLOAD_SHAPEFILE.TITLE));
    },
    [t],
  );

  const handleShapefileClose = useCallback(() => {
    setShapefileAnchorEl(null);
  }, []);

  return (
    <header className={classes.header}>
      <div className={classes.upperNav}>
        <Logo />
        <div className={classes.rightUpperNavModule}>
          <PriceFeed />
          <Notifications />
          <Profile />
        </div>
      </div>
      <div className={classes.nav}>
        {headerItemsList.map(({ id, label, testId, isDisabled }) => (
          <>
            <HeaderItem
              id={id}
              disabled={isDisabled}
              onClick={
                NON_ROUTE_HEADER_IDS.DOWNLOADS === id
                  ? handleShapefileClick
                  : NON_ROUTE_HEADER_IDS.HELP === id
                  ? handleHelpClick
                  : handleMenuItemClick
              }
              showDropDownIcon={nonRouteHeaderIds.includes(id)}
              showBorder={
                (Boolean(anchorHelpEl) && NON_ROUTE_HEADER_IDS.HELP === id) ||
                (Boolean(shapefileAnchorEl) && NON_ROUTE_HEADER_IDS.DOWNLOADS === id) ||
                activeMenuItem === id
              }
              label={t(label)}
              testId={testId}
            />
            {NON_ROUTE_HEADER_IDS.DOWNLOADS === id && (
              <DownloadShapefile anchorEl={shapefileAnchorEl} onClose={handleShapefileClose} />
            )}
            {NON_ROUTE_HEADER_IDS.HELP === id && <Help anchorEl={anchorHelpEl} onClose={handleHelpClose} />}
          </>
        ))}
      </div>
    </header>
  );
}

export const HeaderLandingPage = withWidth()(HeaderComponent);
