import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ShapefileRegion } from './constants';
import { LandingPageShapeFileListState } from './types';

export const initialState: LandingPageShapeFileListState = {
  region: null,
  shapefiles: [],
  status: RequestStatus.IDLE,
  error: null,
};

const shapefileListSlice = createSlice({
  name: 'landingPageShapefileList',
  initialState,
  reducers: {
    loadShapefileList(state, action: PayloadAction<ShapefileRegion>) {
      state.region = action.payload;
      state.status = RequestStatus.LOADING;
      state.error = null;
      state.shapefiles = [];
    },
    shapefileListLoaded(state, action: PayloadAction<string[]>) {
      state.shapefiles = action.payload;
      state.status = RequestStatus.SUCCESS;
    },
    shapefileListError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.status = RequestStatus.FAILURE;
    },
  },
});

export const { actions, reducer, name: sliceKey } = shapefileListSlice;
